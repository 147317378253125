import React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useLocation as useRouterLocation, useNavigate } from 'react-router-dom';
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

// Create theme for MUI components
const theme = createTheme();

function Management() {
  const navigate = useNavigate();
  const currentLocation = useRouterLocation();
  const { adminId, token } = currentLocation.state || {};

  React.useEffect(() => {
    if (isDebugMode) console.log('Admin ID:', adminId);
    if (isDebugMode) console.log('Token:', token);
  }, [adminId, token]);

  const handleTabChange = (event, newValue) => {
    if (isDebugMode) console.log('handleTabChange', newValue);
    switch (newValue) {
      case 0:
        navigate('/admin_trainings', { state: { adminId, token } });
        break;
      case 1:
        navigate('/management', { state: { adminId, token } });
        break;
      default:
        break;
    }
  };

  const handlePayUniform = () => {
    navigate('/pay_uniform', { state: { adminId, token } });
  };

  const handlePro = () => {
    navigate('/pro_info', { state: { adminId, token } });
  };

  const handleUsersInfo = () => {
    navigate('/users_info', { state: { adminId, token } });
  };

  const handleCards = () => {
    navigate('/admin_cards', { state: { adminId, token } });
  };

  const handleTrainingWithoutCard = () => {
    navigate('/training_no_card', { state: { adminId, token } });
  };

  const handleFinanceBalance = () => {
    navigate('/finance_balance', { state: { adminId, token } });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="md">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
          <Avatar
            sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }} // Adjust size as needed
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5">
            ניהול
          </Typography>
          <Tabs
            value={1} // Adjust the value to reflect the correct active tab index
            onChange={handleTabChange}
            aria-label="navigation tabs"
            sx={{ marginBottom: 4 }}
          >
            <Tab label="אימונים" />
            <Tab label="ניהול" />
          </Tabs>

          {/* Using a grid layout for buttons */}
          <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2, width: '100%' }}>
            <Button variant="contained" onClick={handlePayUniform}>
              מדי אימון
            </Button>
            <Button variant="contained" onClick={handlePro}>
              מאמנים
            </Button>
            <Button variant="contained" onClick={handleUsersInfo}>
              משתמשים
            </Button>
            <Button variant="contained" onClick={handleCards}>
              כרטיסיות
            </Button>
            <Button variant="contained" onClick={handleTrainingWithoutCard}>
              אימונים ללא כרטיסייה
            </Button>
            <Button variant="contained" onClick={handleFinanceBalance}>
              דוח הכנסות\הוצאות
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Management;
