import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formatTime, formatDate, getTrainingType } from '../utils'; // Import utility functions
const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const getPreviousTrainings = async (userId, token) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/user_archived_training', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId, token: token }),
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      return jsonResponse.user_archived_training || [];
    } else {
if (isDebugMode)       console.error("Error fetching previous trainings");
    }
  } catch (error) {
if (isDebugMode)     console.error('Error:', error);
  }
  return [];
};

const PreviousTrainings = ({ userId, token }) => {
  const [trainings, setTrainings] = useState([]);
  const [sortField, setSortField] = useState('location'); // Default sort field
  const [sortOrder, setSortOrder] = useState('asc'); // Default sort order

  useEffect(() => {
    const fetchData = async () => {
      const previousTrainings = await getPreviousTrainings(userId, token);
      setTrainings(previousTrainings);
    };
    fetchData();
  }, [userId, token]);

  // Function to handle sorting
  const handleSort = (field) => {
    const newSortOrder = (field === sortField && sortOrder === 'asc') ? 'desc' : 'asc';
    const sortedTrainings = [...trainings].sort((a, b) => {
      if (a[field] < b[field]) return newSortOrder === 'asc' ? -1 : 1;
      if (a[field] > b[field]) return newSortOrder === 'asc' ? 1 : -1;
      return 0;
    });
    setTrainings(sortedTrainings);
    setSortField(field);
    setSortOrder(newSortOrder);
  };

  return (
    <Box sx={{ p: 2, borderRadius: '4px', maxHeight: 300, overflowY: 'scroll' }}>
      <Box sx={{ mt: 2 }}>
        {trainings.length > 0 ? (
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ padding: '8px', borderBottom: '1px solid #ddd', cursor: 'pointer' }} onClick={() => handleSort('location')}>
                  כתובת {sortField === 'location' && (sortOrder === 'asc' ? '▲' : '▼')}
                </th>
                <th style={{ padding: '8px', borderBottom: '1px solid #ddd', cursor: 'pointer' }} onClick={() => handleSort('training_type')}>
                  סוג אימון {sortField === 'training_type' && (sortOrder === 'asc' ? '▲' : '▼')}
                </th>
                <th style={{ padding: '8px', borderBottom: '1px solid #ddd', cursor: 'pointer' }} onClick={() => handleSort('trainee_name')}>
                  כדורגלן {sortField === 'trainee_name' && (sortOrder === 'asc' ? '▲' : '▼')}
                </th>
                <th style={{ padding: '8px', borderBottom: '1px solid #ddd', cursor: 'pointer' }} onClick={() => handleSort('time')}>
                  שעה {sortField === 'time' && (sortOrder === 'asc' ? '▲' : '▼')}
                </th>
                <th style={{ padding: '8px', borderBottom: '1px solid #ddd', cursor: 'pointer' }} onClick={() => handleSort('date')}>
                  תאריך {sortField === 'date' && (sortOrder === 'asc' ? '▲' : '▼')}
                </th>
              </tr>
            </thead>
            <tbody>
              {trainings.map(training => (
                <tr key={training.trainee_training_id}>
                  <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{training.location}</td>
                  <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{getTrainingType(training.training_type)}</td>
                  <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{training.trainee_name}</td>
                  <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{formatTime(training.time)}</td>
                  <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{formatDate(training.date)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Typography>אין היסטוריית אימונים</Typography>
        )}
      </Box>
    </Box>
  );
};

export default PreviousTrainings;
