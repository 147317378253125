import React, { useState, useCallback } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useLocation as useRouterLocation, useNavigate } from 'react-router-dom';
import CardsRecord from './CardsRecord';
import AddCardDialog from './AddCardDialog'; // Import the AddCardDialog component
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure
const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

// Create theme for MUI components
const theme = createTheme();

function AdminCards() {
  const navigate = useNavigate();
  const currentLocation = useRouterLocation();
  const { adminId, token } = currentLocation.state || {}; // Extract adminId and token

  const [dialogOpen, setDialogOpen] = useState(false); // State to control dialog visibility
  const [refreshKey, setRefreshKey] = useState(0); // State to trigger data refresh
const [dialogType, setDialogType] = useState(''); // Define dialogType and setDialogType

  React.useEffect(() => {
if (isDebugMode)     console.log('Admin ID:', adminId);
if (isDebugMode)     console.log('Token:', token);
  }, [adminId, token]);

  const handleTabChange = (event, newValue) => {
if (isDebugMode)     console.log('handleTabChange', newValue);
    switch (newValue) {
      case 0:
        navigate('/admin_trainings', { state: { adminId, token } });
        break;
      case 1:
        navigate('/management', { state: { adminId, token } });
        break;
      default:
        break;
    }
  };

  const handleCreateNewCard = () => {
    setDialogOpen(true); // Open the dialog
    setDialogType('card'); // או 'payment' במקרה של הוספת תשלום

  };

  const handleCloseDialog = (success) => {
    setDialogOpen(false); // Close the dialog
    if (success) {
      setRefreshKey(prevKey => prevKey + 1); // Trigger a data refresh
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="md">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
          <Avatar
            sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }} // Adjust size as needed
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5">
            כרטיסיות
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
            <Button
              variant="contained"
              onClick={handleCreateNewCard}
              sx={{ mb: 2 }}
            >
              כרטיסייה חדשה
            </Button>
            <Box sx={{ mb: 2, width: '100%', maxWidth: 600 }}>
              <CardsRecord adminId={adminId} token={token} refreshKey={refreshKey} />
            </Box>
          </Box>
        </Box>
      </Container>
      <AddCardDialog open={dialogOpen} onClose={handleCloseDialog} adminId={adminId} token={token} />
    </ThemeProvider>
  );
}

export default AdminCards;
