import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { useLocation as useRouterLocation, useNavigate } from 'react-router-dom';
import AdminActiveTrainings from './AdminActiveTrainings';
import AdminPreviousTrainings from './AdminPreviousTrainings';
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

// Create theme for MUI components
const theme = createTheme();

function AdminTrainings() {
  const navigate = useNavigate();
  const currentLocation = useRouterLocation();
  const { adminId, token } = currentLocation.state || {};
  const [showAllTrainings, setShowAllTrainings] = useState(false);

  React.useEffect(() => {
    if (isDebugMode) console.log('Admin ID:', adminId);
    if (isDebugMode) console.log('Token:', token);
  }, [adminId, token]);

  const handleTabChange = (event, newValue) => {
    if (isDebugMode) console.log('handleTabChange', newValue);
    switch (newValue) {
      case 0:
        navigate('/admin_trainings', { state: { adminId, token } });
        break;
      case 1:
        navigate('/management', { state: { adminId, token } });
        break;
//      case 1:
//        navigate('/admin_cards', { state: { adminId, token } });
//        break;
//      case 2:
//        navigate('/users_info', { state: { adminId, token } });
//        break;
//      case 3:
//        navigate('/pro_info', { state: { adminId, token } });
//        break;
      default:
        break;
    }
  };

  const handleCreateNewTraining = () => {
    navigate('/add_training', { state: { adminId, token } });
  };

  const handleCheckboxChange = () => {
    setShowAllTrainings((prev) => !prev); // Toggle the state
    if (isDebugMode) console.log('Checkbox toggled, showAllTrainings:', !showAllTrainings);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="md">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
          <Avatar
            sx={{ m: 1, bgcolor: 'secondary.main', width:100, height: 100 }} // Adjust size as needed
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5">
            אימונים
          </Typography>
          <Tabs
            value={0} // Adjust the value to reflect the correct active tab index
            onChange={handleTabChange}
            aria-label="navigation tabs"
            sx={{ marginBottom: 4 }}
          >
            <Tab label="אימונים" />
            <Tab label="ניהול" />
          </Tabs>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
            <Button
              variant="contained"
              onClick={handleCreateNewTraining}
              sx={{ mb: 2 }}
            >
              יצירת אימון
            </Button>
            <Box sx={{ mb: 2, width: '100%', maxWidth: 600 }}>
              <Typography variant="h6" align="center">אימונים פעילים</Typography>
              <Box>
                <label>
                  <input
                    type="checkbox"
                    checked={showAllTrainings}
                    onChange={handleCheckboxChange}
                  />
                  הצג את כל סוגי האימונים
                </label>
              </Box>
              <AdminActiveTrainings adminId={adminId} token={token} showAll={showAllTrainings} />
            </Box>
            <Box sx={{ mb: 2, width: '100%', maxWidth: 600 }}>
              <Typography variant="h6" align="center">היסטוריית אימונים</Typography>
              <AdminPreviousTrainings adminId={adminId} token={token} showAll={showAllTrainings} />
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default AdminTrainings;
